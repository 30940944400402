import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import {
    versionRoutePath,
    contactRoutePath,
    pricingRoutePath,
    // personalRoutePath,
    privacyPolicyRoutePath,
    cookiesPolicyRoutePath,
    complaintsPolicyRoutePath,
    termsAndConditionsRoutePath,
    applicantsPrivacyPolicyRoutePath,
    applicantsPrivacyPolicyLTRoutePath,
    accumulativeRoutePath,
    personalRoutePath,
    // termsAndConditionsPersonalRoutePath,
} from 'config/path/path';
import useScrollToId from 'hooks/useScrollToId';

import Home from 'pages/Home/Home';
import Contact from 'pages/Contact/Contact';
import Pricing from 'pages/Pricing/Pricing';
import Version from 'pages/Version/Version';
import BePart from 'pages/Main/BePart/BePart';
import Footer from 'pages/Main/Footer/Footer';
import { CookiesPolicy } from 'pages/Legal/CookiesPolicy/CookiesPolicy';
import { PrivacyPolicy } from 'pages/Legal/PrivacyPolicy/PrivacyPolicy';
import { ComplaintsPolicy } from 'pages/Legal/ComplaintsPolicy/ComplaintsPolicy';
import { TermsAndConditions } from 'pages/Legal/TermsAndConditions/TermsAndConditions';
import { ApplicantsPrivacyPolicy } from 'pages/Legal/ApplicantsPrivacyPolicy/ApplicantsPrivacyPolicy';
import { ApplicantsPrivacyPolicyLT } from 'pages/Legal/ApplicantsPrivacyPolicy/ApplicantsPrivacyPolicyLT';
// import { TermsAndConditionsPersonal } from 'pages/Legal/TermsAndConditionsPersonal/TermsAndConditionsPersonal';
import { HomeAccumulative } from 'pages/HomeAccumulative/HomeAccumulative.layout';
import { HomePersonal } from 'pages/HomePersonal/HomePersonal.layout';
import { ENABLE_PERSONAL_PAGE } from 'config/featureFlags/featureFlags';
import AccumulativeBanner from 'components/atoms/accumulativeBanner/AccumulativeBanner.layout';
import { AccumulativeBannerState } from 'components/atoms/accumulativeBanner/AccumulativeBanner.types';
import { useTranslation } from 'react-i18next';
import Cookies from './Cookies/Cookies';
import Navbar from './Navbar/Navbar';
import { StyledMain } from './Main.styled';
import { MainProps } from './Main.types';


export const Main: React.FC<MainProps> = () => {
    const [accumulativeBannerState, setAccumulativeBannerState] = useState(AccumulativeBannerState.OPEN);
    const { pathname } = useLocation();

    const showBanner = !pathname.includes(accumulativeRoutePath);

    useScrollToId();

    const { i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const showAccumulativeBanner = currentLanguage !== 'pl';

    return (
        <>
            <Navbar />
            {showAccumulativeBanner && <AccumulativeBanner accumulativeBannerState={accumulativeBannerState} setAccumulativeBannerState={setAccumulativeBannerState} />}
            <StyledMain accumulativeBannerState={accumulativeBannerState} showBanner={showBanner}>
                <Routes>
                    <Route path={versionRoutePath} element={<Version />} />
                    <Route path={pricingRoutePath} element={<Pricing />} />
                    <Route path={contactRoutePath} element={<Contact />} />
                    <Route path={privacyPolicyRoutePath} element={<PrivacyPolicy />} />
                    <Route path={cookiesPolicyRoutePath} element={<CookiesPolicy />} />
                    <Route path={complaintsPolicyRoutePath} element={<ComplaintsPolicy />} />
                    <Route path={termsAndConditionsRoutePath} element={<TermsAndConditions />} />
                    {/* CPP-2221 */}
                    {/* <Route path={termsAndConditionsPersonalRoutePath} element={<TermsAndConditionsPersonal />} /> */}
                    <Route path={applicantsPrivacyPolicyRoutePath} element={<ApplicantsPrivacyPolicy />} />
                    <Route path={applicantsPrivacyPolicyLTRoutePath} element={<ApplicantsPrivacyPolicyLT />} />
                    {/* CPP-2221 */}

                    {/* CPP-2221 */}
                    {/* <Route path={personalRoutePath} element={<HomePersonal />} /> */}
                    { ENABLE_PERSONAL_PAGE && <Route path={personalRoutePath} element={<HomePersonal />} />}

                    <Route path={accumulativeRoutePath} element={<HomeAccumulative />} />

                    <Route index element={<Home />} />
                    <Route path="*" element={<Home />} />
                </Routes>
                <BePart />
            </StyledMain>
            <Footer />
            <Cookies />
        </>
    );
};
