import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CookieBanner, ID } from '@okeo/components';
import { useLocation } from 'react-router-dom';

import { Facebook } from 'assets/icons/Facebook';
import { LinkedIn } from 'assets/icons/LinkedIn';
import { X } from 'assets/icons/X';
import { getCurrentYear } from 'utils/date-tools';
import { stringToAttributeString } from 'utils/string-tools';
import { getPageType, PageType } from 'config/path/path.helpers';
import TranslationWithMarkup from 'components/atoms/TranslationWithMarkup/TranslationWithMarkup';
import ZendeskChat from 'components/atoms/ZendeskChat/ZendeskChat.layout';
import useWindowSize from 'hooks/useWindowSize';

import { FooterListElement } from './Footer.helpers';
import {
    Addons,
    FooterEnd,
    FooterLabel,
    FooterList,
    FooterSocialMediaList,
    FooterText,
    FooterTitle,
    FooterTopContent,
    FooterTopWrapper,
} from './Footer.styled';
import { FooterListElememtValues, FooterProps } from './Footer.types';

const year = getCurrentYear;

export const Footer: React.FC<FooterProps> = ({ t }) => {
    const { hash } = useLocation();
    const { height } = useWindowSize();
    const { i18n } = useTranslation();

    const listType = getPageType() === PageType.PERSONAL ? 'links.listPersonal' : 'links.list';
    const footerList: FooterListElememtValues[] = t(listType, { returnObjects: true });

    const listItems = Object.values(footerList).map((listElement) => (
        <FooterListElement
            url={listElement.url}
            label={listElement.label}
            key={`footer-list-${stringToAttributeString(listElement.label)}`}
        />
    ));

    const letZendeskDown = () => document.getElementById(ID.Zendesk)?.classList.add("down");

    return (
        <footer>
            <FooterTopWrapper>
                <FooterTopContent>
                    <div>
                        <FooterTitle>{t('contact.header.label')}</FooterTitle>
                        <FooterText>
                            <Trans t={t} i18nKey="contact.text.label" />
                            <TranslationWithMarkup t={t} translationKey="contact.text.mail" />
                        </FooterText>
                    </div>
                    <div>
                        <FooterTitle>{t('links.header.label')}</FooterTitle>
                        <FooterList>
                            {listItems}
                        </FooterList>
                    </div>

                    <div>
                        <FooterTitle>{t('connect.header.label')}</FooterTitle>
                        <FooterSocialMediaList>
                            <a href={t('socialmedia.linkedin.url')} target="_blank" rel="noreferrer" aria-label="LinkedIn">
                                <LinkedIn />
                            </a>
                            <a href={t('socialmedia.twitter.url')} target="_blank" rel="noreferrer" aria-label="X">
                                <X />
                            </a>
                            <a href={t('socialmedia.facebook.url')} target="_blank" rel="noreferrer" aria-label="Facebook">
                                <Facebook />
                            </a>
                        </FooterSocialMediaList>
                    </div>
                </FooterTopContent>
                <FooterLabel>
                    <TranslationWithMarkup t={t} translationKey="text.label" />
                </FooterLabel>
            </FooterTopWrapper>

            <FooterEnd>
                <TranslationWithMarkup
                    t={t}
                    translationKey="date.label"
                    values={{ year }}
                />
            </FooterEnd>

            <Addons>
                <ZendeskChat />
                <CookieBanner hash={hash} deviceHeight={height || 600} letZendeskUp={letZendeskDown} locale={i18n.language} />
            </Addons>
        </footer>
    );
};
