import { ID } from 'config/layout/layout.types';
import { RoutePath } from './path.types';


export const defaultRoutePath: RoutePath = '/';

export const legalPath = '/legal';
export const versionRoutePath: RoutePath = '/version';

export const faqSection: RoutePath = `/#${ID.FAQ}`;
export const welcomeRoutePath: RoutePath = `/#${ID.Root}`;
export const cookieBannerHash: RoutePath = `#${ID.CookieBanner}`;

export const pricingRoutePath: RoutePath = '/pricing';
export const aboutUsRoutePath: RoutePath = '/about-us';
export const contactRoutePath: RoutePath = '/contact';

export const termsAndConditionsRoutePath: RoutePath = `${legalPath}/terms-and-conditions`;

export const applicantsPrivacyPolicyRoutePath: RoutePath = `${legalPath}/applicants-privacy-policy`;
export const applicantsPrivacyPolicyLTRoutePath: RoutePath = `${legalPath}/kandidatu-privatumo-politika`;
export const privacyPolicyRoutePath: RoutePath = `${legalPath}/privacy-policy`;
export const cookiesPolicyRoutePath: RoutePath = `${legalPath}/cookies-policy`;
export const complaintsPolicyRoutePath: RoutePath = `${legalPath}/complaints-policy`;

export const personalRoutePath: RoutePath = '/personal';
export const faqPersonalSection: RoutePath = `${personalRoutePath}#${ID.FAQ}`;
export const pricingPersonalPath: RoutePath = `${personalRoutePath}#${ID.PricingPersonal}`;
export const welcomePersonalRoutePath: RoutePath = `${personalRoutePath}#${ID.Root}`;
export const termsAndConditionsPersonalRoutePath: RoutePath = `${legalPath}/terms-and-conditions-personal`;


export const accumulativeRoutePath: RoutePath = '/accumulative-account';
export const pricingaccumulativePath: RoutePath = `${accumulativeRoutePath}#${ID.PricingAccumulative}`;
export const welcomeAccumulativeRoutePath: RoutePath = `${accumulativeRoutePath}#${ID.Root}`;

